import { MandateStatus } from '../constants/onboarding';
import { PaymentMethodFvLinkResponse, PaymentMethodType } from '../entities/api/paymentMethod';

export const getRecipientNameFromPaymentMethod = (paymentMethod: PaymentMethodFvLinkResponse): string => {
  if (paymentMethod.payment_method_type === PaymentMethodType.MANDATE) {
    return paymentMethod.mandate.recipient.name;
  }

  if (paymentMethod.payment_method_type === PaymentMethodType.CARD) {
    return paymentMethod.card.recipient.name;
  }

  return 'UNKNOWN';
};

export const getStatusFromPaymentMethod = (paymentMethod: PaymentMethodFvLinkResponse): string => {
  if (paymentMethod.payment_method_type === PaymentMethodType.MANDATE) {
    return paymentMethod.mandate.mandate_status;
  }

  if (paymentMethod.payment_method_type == PaymentMethodType.CARD) {
    return paymentMethod.card.status;
  }

  return 'UNKNOWN';
};

export const getMaskedAccountNumberFromPaymentMethod = (paymentMethod: PaymentMethodFvLinkResponse): string => {
  if (paymentMethod.payment_method_type === PaymentMethodType.MANDATE) {
    if (paymentMethod.mandate.sender_account !== undefined) {
      return (paymentMethod.mandate.sender_account.account_number_masked ?? '').replaceAll('X', '*');
    }
    return '';
  }

  if (paymentMethod.payment_method_type === PaymentMethodType.CARD) {
    return `****${paymentMethod.card.card_details?.last4 ?? ''}`;
  }

  return '';
};

export const isPaymentMethodSucceeded = (paymentMethod: PaymentMethodFvLinkResponse): boolean => {
  if (paymentMethod.payment_method_type === PaymentMethodType.MANDATE) {
    return paymentMethod.mandate.mandate_status === MandateStatus.SUCCEEDED;
  }

  if (paymentMethod.payment_method_type === PaymentMethodType.CARD) {
    return paymentMethod.card.status === 'SUCCEEDED';
  }

  return false;
};
