import { Switch, Route } from 'react-router-dom';

import MandateAuthChecklistRedirectScreen from '../pages/mandate/AuthChecklistRedirectPage';
import MandateSubmissionFailedScreen from '../pages/mandate/SubmissionFailedScreen';
import MandateSubmittedScreen from '../pages/mandate/SubmittedScreen';
import PaymentLinkErrorScreen from '../pages/Onboarding/PaymentLinkErrorScreen';
import AutopayConsentScreen from '../pages/payment/bankDirectDebit/AutopayConsentScreen';
import PaymentConfirmationScreen from '../pages/payment/bankDirectDebit/PaymentConfirmationScreen';
import PaymentPollingScreen from '../pages/payment/bankDirectDebit/PaymentPollingScreen';
import PaymentSubmittedScreen from '../pages/payment/bankDirectDebit/PaymentSubmittedScreen';
import { MandateRoutes } from './routes';

export default function MandateRouter(): JSX.Element {
  return (
    <Switch>
      <Route path={MandateRoutes.PaymentConfirm}>
        <PaymentConfirmationScreen />
      </Route>
      <Route path={MandateRoutes.PaymentPoll}>
        <PaymentPollingScreen />
      </Route>
      <Route path={MandateRoutes.PaymentSubmitted}>
        <PaymentSubmittedScreen />
      </Route>
      <Route path={MandateRoutes.MandateAuthChecklistRedirect}>
        <MandateAuthChecklistRedirectScreen />
      </Route>
      <Route path={MandateRoutes.AutopayConsent}>
        <AutopayConsentScreen />
      </Route>
      <Route path={MandateRoutes.PaymentLinkError}>
        <PaymentLinkErrorScreen />
      </Route>
      <Route path={MandateRoutes.SubmittedMessage}>
        <MandateSubmittedScreen />
      </Route>
      <Route path={MandateRoutes.SubmissionFailedMessage}>
        <MandateSubmissionFailedScreen />
      </Route>
    </Switch>
  );
}
