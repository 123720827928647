import { useCallback, useEffect, useState, useMemo, Fragment } from 'react';

import { InfoOutlined } from '@mui/icons-material';
import SyncIcon from '@mui/icons-material/Sync';
import {
  CircularProgress,
  FormControlLabel,
  Grid,
  IconButton,
  Radio,
  RadioGroup,
  Tooltip,
  Typography,
  ClickAwayListener,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';

import { BorderedGrid } from '../../../components/BorderedGrid';
import { LoadingButton } from '../../../components/Buttons';
import { ConsentDisclaimer } from '../../../components/ConsentDisclaimer';
import { MidContainer } from '../../../components/Containers';
import LinkHeader from '../../../components/LinkHeader';
import { FuturePayments, ManualPaymentProvider, OnboardingFlow } from '../../../constants/onboarding';
import { UIModes } from '../../../entities';
import useAPIClient from '../../../hooks/useClient';
import useCustomizations from '../../../hooks/useCustomizations';
import useSearchParams from '../../../hooks/useSearchParams';
import useToggle from '../../../hooks/useToggle';
import { OnboardingRoutes, FpsRoutes, GenericErrorRoutes } from '../../../routers/routes';
import { decodeToken, getManualPaymentProvider, getFuturePayments, getOnboardingFlow } from '../../../services';
import amplitude, { PAGE_VIEWS } from '../../../services/amplitude';
import { getApiErrorPath } from '../../../utils/error_page';
import Screen from '../../common/Screen';

enum PaymentType {
  BANK_DIRECT_DEBIT = 'MANDATE',
  FPS = 'MANUAL',
  CARD = 'CARD',
}

const getManualPaymentText = (provider: ManualPaymentProvider): { label: string; subtext: string } => {
  if (provider === ManualPaymentProvider.SG_PAYNOW) {
    return {
      label: 'PayNow',
      subtext: 'One-time payment by PayNow QR code.',
    };
  }
  // lets use HK_FPS as default
  return {
    label: 'FPS payment',
    subtext: 'One-time payment by FPS QR code.',
  };
};

const getHelperText = (helperText: HelperText, futurePayments: FuturePayments) => {
  if (futurePayments !== FuturePayments.UNKNOWN) {
    return helperText.futurePayments;
  }
  return helperText.regular;
};

const getAutopayText = (futurePayments: FuturePayments) => {
  if (futurePayments === FuturePayments.AUTOPAY) {
    return 'Pay bills when due';
  }
  return 'You control each payment';
};

interface TokenClaimPaymentMethod {
  type: string;
  fee: string;
}

interface HelperText {
  futurePayments: string; // helper text to show when futurePayments is ENABLED
  regular: string; // helper text to show when futurePayments is DISABLED
}

interface PaymentMethodComponent {
  paymentType: PaymentType;
  paymentMethod: TokenClaimPaymentMethod; // payment method might not be allowed, in which case it will be undefined
  optionLabel: JSX.Element;
  helperText: HelperText;
  recurring: boolean; // if the payment method supports recurring payments i.e. Mandates, Cards
}

/**
 * This function contains a hook that refreshes the payment_attempt and retrieves a new token
 * This new token should be used for all API calls since it contains the most up-to-date payment_attempt_id
 *
 * There might be a case where the `oldToken` contains a payment_attempt_id that has an unusable status, so we
 * should not use the oldToken for any API calls. Remember to always pass the `newOnboardingSearchParams`
 * on redirection since it contains the new token
 */

export default function SelectPaymentTypeScreen(): JSX.Element {
  const { t } = useTranslation();
  const history = useHistory();
  const client = useAPIClient();
  const { customizationInfo } = useCustomizations();
  const { params, searchParamsString } = useSearchParams();
  const [pageLoading, setPageLoading] = useState(true);
  const [buttonLoading, setButtonLoading] = useState(false);
  const [navigated, setNavigated] = useState(false); // We'll use this to determine if we navigated to a different website, e.g. Stripe
  const [paymentType, setPaymentType] = useState('');
  const [buttonDisable, setButtonDisable] = useState(true);

  const [newOnboardingToken, setNewOnboardingToken] = useState(params.token);
  const [newOnboardingSearchParams, setNewOnboardingSearchParams] = useState(searchParamsString);
  const [tooltipOpen, toggleTooltip] = useToggle(false);

  const oldToken = params.token;
  const decodedToken = decodeToken(oldToken);
  const allowedPaymentMethodsRaw = decodedToken.paymentMethodsWithFees as string;
  const allowedPaymentMethods = useMemo((): TokenClaimPaymentMethod[] => {
    if (allowedPaymentMethodsRaw) {
      return JSON.parse(allowedPaymentMethodsRaw);
    }
    return [];
  }, [allowedPaymentMethodsRaw]);

  const manualPaymentProvider = getManualPaymentProvider(newOnboardingToken);
  const futurePayments = getFuturePayments(newOnboardingToken);

  const getTooltipText = (): string => {
    return futurePayments === FuturePayments.CLICK_TO_PAY
      ? t('Your bank/card details will be saved. Only you can initiate a payment.')
      : t('Enjoy hassle-free payments: automatically pay each invoice when due, and receive a notification.');
  };

  const availablePaymentMethods = useMemo((): PaymentMethodComponent[] => {
    const manualPaymentText = getManualPaymentText(manualPaymentProvider);
    const allPaymentMethods = [
      {
        paymentType: PaymentType.BANK_DIRECT_DEBIT,
        optionLabel: (
          <Typography variant="h6">
            <b>{t('Bank direct debit')}</b> ({t('recommended')})
          </Typography>
        ),
        helperText: {
          regular: t('Best for multiple payments. You control each payment (default), or can autopay.'),
          futurePayments: t('Pay securely from your bank account.'),
        },
        recurring: true,
      },
      {
        paymentType: PaymentType.FPS,
        optionLabel: (
          <Typography variant="h6" fontWeight="bold">
            {t(manualPaymentText.label)}
          </Typography>
        ),
        helperText: {
          regular: t(manualPaymentText.subtext),
          futurePayments: t(manualPaymentText.subtext),
        },
        recurring: false,
      },
      {
        paymentType: PaymentType.CARD,
        optionLabel: (
          <Typography variant="h6" fontWeight="bold">
            {t('Card payment')}
          </Typography>
        ),
        helperText: {
          regular: t('One-time payment by credit card or debit card.'),
          futurePayments: t(''),
        },
        recurring: true,
      },
    ];

    // return only the payment methods where the corresponding payment type is in the allowed list
    return allPaymentMethods.reduce((availablePaymentMethods, method) => {
      const tokenClaimPaymentMethod = allowedPaymentMethods.find((pm) => pm.type === method.paymentType);

      return tokenClaimPaymentMethod === undefined
        ? availablePaymentMethods
        : [...availablePaymentMethods, { ...method, paymentMethod: tokenClaimPaymentMethod }];
    }, [] as PaymentMethodComponent[]);
  }, [allowedPaymentMethods, manualPaymentProvider, t]);

  const recurringPaymentMethods = useMemo(
    (): PaymentMethodComponent[] => availablePaymentMethods.filter((method) => method.recurring),
    [availablePaymentMethods],
  );

  const onetimePaymentMethods = useMemo(
    (): PaymentMethodComponent[] => availablePaymentMethods.filter((method) => !method.recurring),
    [availablePaymentMethods],
  );

  useEffect(() => {
    setButtonDisable(true);
    if (availablePaymentMethods.length === 0) {
      history.push('/error?error_code=PAYMENT_DISABLED&customer_app_id=' + decodedToken.customerAppId);
    }

    setPaymentType(availablePaymentMethods[0].paymentType);
    setButtonDisable(false);
  }, [availablePaymentMethods, decodedToken.customerAppId, history]);

  useEffect(() => {
    amplitude.trackPageView(PAGE_VIEWS.SELECT_PAYMENT_TYPE, undefined, {
      manualPaymentProvider: manualPaymentProvider,
    });
  }, [manualPaymentProvider]);

  const resetPaymentAttempt = useCallback(async () => {
    try {
      const response = await client.refreshPaymentAttempt(oldToken);

      // we could have gotten error or success redirect
      const url = response.redirect_url;
      const urlObject = new URL(url);

      const onboardingFlow = getOnboardingFlow(urlObject.searchParams.get('token') ?? '');

      // if returned flow is not onboarding (i.e. UNKNOWN), then we should redirect to that URL
      if (onboardingFlow === OnboardingFlow.Unknown) {
        window.location.href = url;
      }

      // if we are here, then we successfully reset the payment attempt and should be doing onboarding flow
      // get the newest token and new search params
      setNewOnboardingToken(urlObject.searchParams.get('token') ?? '');
      setNewOnboardingSearchParams(urlObject.searchParams.toString());
      setPageLoading(false);
    } catch (err) {
      history.push(getApiErrorPath(err as any, searchParamsString));
    }
  }, [client, history, searchParamsString, oldToken]);

  useEffect(() => {
    setPageLoading(true);
    resetPaymentAttempt();
  }, [resetPaymentAttempt]);

  const bankDirectDebitAction = useCallback(() => {
    history.push({ pathname: OnboardingRoutes.SelectSenderType, search: newOnboardingSearchParams });
  }, [history, newOnboardingSearchParams]);

  const fpsAction = useCallback(async () => {
    //step 1: set button loading
    setButtonLoading(true);

    //step 2: make request to get fps token
    try {
      const response = await client.getFpsToken(newOnboardingToken);
      const fpsToken = response.fps_token.access_token;
      const newSearchParams = new URLSearchParams(newOnboardingSearchParams);
      newSearchParams.set('token', fpsToken);
      history.push({ pathname: FpsRoutes.PaymentConfirm, search: newSearchParams.toString() });
    } catch (err) {
      history.push(getApiErrorPath(err as any, newOnboardingSearchParams));
    }
  }, [history, client, newOnboardingSearchParams, newOnboardingToken]);

  const cardAction = useCallback(async () => {
    setButtonLoading(true);

    try {
      const response = await client.getRedirectUrlToCardProcessorPage(newOnboardingToken);

      if (response.card_processor_redirect_uri !== '') {
        // Set `navigated=true` in the state, so that it is saved in the bfcache
        // Then when we navigate back we can check for this and manually reset the payment attempt
        setNavigated(true);
        window.location.href = response.card_processor_redirect_uri;
      } else {
        history.push({
          pathname: GenericErrorRoutes.Base,
          search: newOnboardingSearchParams,
        });
      }
    } catch (err) {
      history.push(getApiErrorPath(err as any, newOnboardingSearchParams));
    }
  }, [history, newOnboardingSearchParams, client, newOnboardingToken]);

  const BottomContent = () => {
    return (
      <>
        <LoadingButton
          variant="contained"
          loading={buttonLoading}
          disabled={buttonDisable}
          onClick={() => {
            amplitude.trackPaymentTypeSelection(paymentType);
            if (paymentType === PaymentType.BANK_DIRECT_DEBIT) {
              bankDirectDebitAction();
            }
            if (paymentType === PaymentType.FPS) {
              fpsAction();
            }
            if (paymentType === PaymentType.CARD) {
              cardAction();
            }
          }}
        >
          {t('messageScreenContinue')}
        </LoadingButton>
        <ConsentDisclaimer />
      </>
    );
  };

  if (pageLoading) {
    return (
      <Screen showCloseButton={false} showBackButton={false}>
        <MidContainer justifyContent="center" alignItems="center">
          <CircularProgress id="loading-spinner" />
        </MidContainer>
      </Screen>
    );
  }

  window.addEventListener('pageshow', async (event) => {
    if (event.persisted) {
      // This means page restored from bfcache after navigation
      // So we want to manually reset the payment attempt
      // And set buttonLoading to false
      if (navigated === true) {
        await resetPaymentAttempt();
        setNavigated(false);
        setButtonLoading(false);
      }
    }
  });

  const getAutopaySetting = (futurePaymentOptions: FuturePayments) => {
    if (futurePaymentOptions === FuturePayments.AUTOPAY) {
      return (
        <Typography color="primary" component="span" fontWeight="bold">
          {t('ON')}
        </Typography>
      );
    }
    return (
      <Typography color="#727272" component="span" fontWeight="bold">
        {t('OFF')}
      </Typography>
    );
  };

  // paymentMethodOption returns payment method option component for a given method
  const paymentMethodOption = (method: PaymentMethodComponent, futurePaymentOptions: FuturePayments) => {
    return (
      <Fragment key={method.paymentType}>
        <FormControlLabel
          sx={{ padding: '0 10px' }}
          value={method.paymentType}
          control={<Radio sx={{ padding: '3px 9px' }} />}
          label={method.optionLabel}
        />
        <Grid container sx={{ marginBottom: '15px' }}>
          <Grid item width="45px" />
          <Grid item xs>
            <Typography variant="body2">{t(getHelperText(method.helperText, futurePaymentOptions))}</Typography>
            {method.paymentMethod.fee !== '' && (
              <Typography variant="body2">
                {t('Fee')}: {method.paymentMethod.fee.replace('min', t('min'))}
              </Typography>
            )}
          </Grid>
        </Grid>
      </Fragment>
    );
  };

  return (
    <Screen
      showBackButton
      showCloseButton={params.uiMode !== UIModes.standalone}
      bottomStickyComponent={<BottomContent />}
    >
      <LinkHeader
        src={customizationInfo.logoUrl}
        message={customizationInfo.displayName ? `${t('Pay')} ${customizationInfo.displayName}` : t('Pay now')}
        alt="Header logo"
      />
      {futurePayments === FuturePayments.UNKNOWN && (
        // existing screen without grouping
        <MidContainer sx={{ gap: 1 }}>
          <Typography variant="h6" align="center" gutterBottom fontWeight="bold">
            {t('selectPaymentMethod')}
          </Typography>
          <RadioGroup
            value={paymentType}
            onChange={(e) => {
              setPaymentType(e.target.value as PaymentType);
              setButtonDisable(false);
            }}
          >
            {availablePaymentMethods.map((method) => paymentMethodOption(method, futurePayments))}
          </RadioGroup>
        </MidContainer>
      )}
      {futurePayments !== FuturePayments.UNKNOWN && (
        // new screen with payment methods grouped by whether its recurring or not
        <MidContainer sx={{ gap: 1 }}>
          <Typography variant="h6" align="center" gutterBottom fontWeight="bold">
            {t('selectPaymentMethod')}
          </Typography>
          <RadioGroup
            value={paymentType}
            onChange={(e) => {
              setPaymentType(e.target.value as PaymentType);
              setButtonDisable(false);
            }}
          >
            {
              // render list of recurring payment options
              recurringPaymentMethods.map((method) => paymentMethodOption(method, futurePayments))
            }
            {
              // Only show the autopay settings if there is recurringPaymentMethods
              recurringPaymentMethods.length > 0 && (
                <Grid container sx={{ margin: '10px 0', backgroundColor: '#E5E5E5', padding: '8px 10px' }}>
                  <Grid container sx={{ padding: 0, margin: 0 }}>
                    <Grid item height="25px" width="35px">
                      <SyncIcon fontSize="small" />
                    </Grid>
                    <Grid item xs>
                      <Typography variant="h6" fontWeight="bold">
                        {t('Autopay')}: {getAutopaySetting(futurePayments)}
                      </Typography>
                    </Grid>
                    <Grid>
                      <ClickAwayListener onClickAway={() => toggleTooltip(false)}>
                        <Tooltip
                          sx={{ padding: 0 }}
                          title={getTooltipText()}
                          arrow
                          open={tooltipOpen}
                          onClose={() => toggleTooltip()}
                          disableFocusListener
                          disableHoverListener
                          disableTouchListener
                        >
                          <IconButton onClick={() => toggleTooltip()}>
                            <InfoOutlined fontSize="small" />
                          </IconButton>
                        </Tooltip>
                      </ClickAwayListener>
                    </Grid>
                  </Grid>
                  <Grid container>
                    <Grid item width="35px" />
                    <Grid item xs>
                      <Typography variant="body2">{t(getAutopayText(futurePayments))}</Typography>
                    </Grid>
                  </Grid>
                </Grid>
              )
            }
            {
              // only show the border box if we have at least one non recurring payment
              onetimePaymentMethods.length > 0 && (
                <BorderedGrid
                  sx={{ margin: '25px 0 0 0' }}
                  border={1}
                  borderColor="#D3D3D3"
                  borderRadius={1}
                  title={t('Or pay one-time only')}
                >
                  <Grid sx={{ margin: '-10px 0 -10px' }}>
                    {
                      // render list of one-time / non recurring payment options
                      onetimePaymentMethods.map((method) => paymentMethodOption(method, futurePayments))
                    }
                  </Grid>
                </BorderedGrid>
              )
            }
          </RadioGroup>
        </MidContainer>
      )}
    </Screen>
  );
}
