import { useEffect } from 'react';

import CheckIcon from '@mui/icons-material/CheckCircle';
import { Typography } from '@mui/material';
import { createTheme } from '@mui/material/styles';
import { useTranslation } from 'react-i18next';

import { UIModes } from '../../entities';
import useCustomizations from '../../hooks/useCustomizations';
import useRedirectURI from '../../hooks/useRedirectURI';
import useSearchParams from '../../hooks/useSearchParams';
import { getTheme } from '../../services';
import amplitude, { PAGE_VIEWS } from '../../services/amplitude';
import { GenericInfoScreen } from '../Onboarding/MessageScreen';

export default function PaymentGenericSuccessScreen(): JSX.Element {
  const { t } = useTranslation();
  const theme = createTheme(getTheme());
  const { customizationInfo } = useCustomizations();
  const title = 'success';
  const header = 'Payment completed';
  const body = 'Your payment has been received and successfully processed';

  const { params } = useSearchParams();
  const { setRedirectURI } = useRedirectURI();

  useEffect(() => {
    if (params.uiMode === UIModes.redirect && typeof params.redirect_uri === 'string') {
      setRedirectURI(params.redirect_uri);
    }
  }, [params.uiMode, params.redirect_uri, setRedirectURI]);

  useEffect(() => {
    amplitude.trackPageView(PAGE_VIEWS.SUCCESS, { title, body, header });
  });

  return (
    <GenericInfoScreen
      theme={theme}
      uiMode={params.uiMode as UIModes}
      showBackButton={false} // don't show back button; user might think we processed payment >1 times
      title={title}
      logoUrl={customizationInfo.logoUrl}
      iconComponent={<CheckIcon color="primary" />}
      titleComponent={
        <Typography variant="h5" fontWeight="bold">
          {t(header)}
        </Typography>
      }
      pageContentComponent={<Typography>{t(body)}</Typography>}
    />
  );
}
